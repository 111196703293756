
import { defineComponent, computed } from 'vue'
import MySettingsForm from '@/components/particles/forms/SettingsForm/MySettingsForm.vue'
import { useCancelToken } from '@/hooks/useCancelToken'
import useStore from '@/store'
import { USER_SETTINGS_ACTION_TYPES } from '@/store/settings/actions'
import { PersonalInfo } from '@/store/settings/types'
import LoadingSpinner from '@/components/particles/common/LoadingSpinner.vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default defineComponent({
  name: 'MySettingsView',
  components: { MySettingsForm, LoadingSpinner },
  setup() {
    const getPersonalInfoCancelToken = useCancelToken()
    const store = useStore()
    const isError = computed(() => {
      if (store.state.userSettings.isError) {
        createToast({
          title: 'Une erreur est survenue',
          description: store.state.userSettings.error
        },
          {
            type: 'danger',
            position: 'bottom-right',
            showIcon: true,
          })
         store.dispatch(
          USER_SETTINGS_ACTION_TYPES.RESET_ERROR_STATE,
          getPersonalInfoCancelToken.value
        )
      }
      return store.state.userSettings.isError
    })
    
    const getPersonalInfo = async () => {
      await store.dispatch(
        USER_SETTINGS_ACTION_TYPES.GET_PERSONAL_INFO,
        getPersonalInfoCancelToken.value
      )
    }
    getPersonalInfo()

    const personalData = computed(() => store.state.userSettings.personalInfo)
    const isLoaded = computed(() => store.state.userSettings.isLoaded)

    const onPersonalInfoUpdate = async (updatedUserInfo: PersonalInfo) => {
      await store.dispatch(
        USER_SETTINGS_ACTION_TYPES.UPDATE_PERSONAL_INFO,
        updatedUserInfo
      )
      getPersonalInfo()
      createToast({
        title: 'Mis à jour effectuée',
      },
        {
          type: 'success',
          position: 'bottom-right',
          showIcon: true,
        })
    }
    return {
      personalData,
      onPersonalInfoUpdate,
      isLoaded,
      isError
    }
  },
})
