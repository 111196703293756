import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-8 pb-4 px-24 bg-white rounded-lg max-w-814 w-full flex-1" }
const _hoisted_2 = { class: "mb-4 text-base-title font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_MySettingsForm = _resolveComponent("MySettingsForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_LoadingSpinner, { class: "h-screen" }, null, 512), [
      [_vShow, _ctx.isLoaded]
    ]),
    _withDirectives(_createElementVNode("div", null, null, 512), [
      [_vShow, _ctx.isError]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('SETTINGS_MY_INFORMATION_TITLE')), 1),
      _createVNode(_component_MySettingsForm, {
        asteriskOnRequired: true,
        personalData: _ctx.personalData,
        onOnSettingsFormSubmit: _ctx.onPersonalInfoUpdate
      }, {
        buttons: _withCtx(({ isSubmitting, isValid }) => [
          _createVNode(_component_UiButton, {
            class: "bg-green text-white text-base py-4 px-12 hover:bg-lightGreen",
            type: "submit",
            loading: isSubmitting,
            disabled: !isValid
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('SETTINGS_CONFIRMATION_BUTTON')), 1)
            ]),
            _: 2
          }, 1032, ["loading", "disabled"])
        ]),
        _: 1
      }, 8, ["personalData", "onOnSettingsFormSubmit"])
    ], 512), [
      [_vShow, !_ctx.isLoaded]
    ])
  ], 64))
}